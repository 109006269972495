<template>
	<div>

		<h1 class="text-2xl font-bold">Good {{ timeOfDay }}, {{ firstName }}</h1>
		<div class="text-gray-600 text-xs font-light mb-6 flex items-center">
			<span class="inline-block h-1 w-1 bg-blue-500 rounded-full mr-2"></span>
			<sm-loader v-if="loading" />
			<template v-else>
				{{ position | sentenceCase }} at {{ company ? company.name : '' }}
			</template>
		</div>

		<div class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 mb-16 border border-blue-200 md:border-none">
			<div class="col-span-1">
				<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-blue-200 relative">
					<span class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
					<div class="flex justify-between items-center mb-4">
						<div class="text-xs">Total Companies</div>
					</div>
					<div class="text-2xl font-bold">
						<sm-loader v-if="loading" />
						<template v-else>
							{{ companies.length }}
						</template>
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-blue-200 relative">
					<span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
					<div class="flex justify-between items-center mb-4">
						<div class="text-xs">Total Loans</div>
					</div>
					<div class="text-2xl font-bold">
						<sm-loader v-if="loading" />
						<template v-else>
							₦ {{ totalLoanAmount | currency }}
						</template>
					</div>
				</div>
			</div>
			<div class="col-span-1">
				<div class="px-8 xl:px-12 py-8 border-t border-b border-l border-r border-blue-200 relative">
					<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
					<div class="flex justify-between items-center mb-4">
						<div class="text-xs">Company Staff</div>
					</div>
					<div class="text-2xl font-bold">
						<sm-loader v-if="loading" />
						<template v-else>
							{{ totalStaff }}
						</template>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="grid grid-cols-2 mb-4">
				<div class="col-span-1">
					<h2 class="text-xl font-bold">All Companies</h2>
				</div>
				<div class="col-span-1">
					<div class="relative">
						<input
							type="search"
							name="search"
							class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
							placeholder="Search for company’s name, status, etc."
							v-model="searchQuery">
						<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
					</div>
				</div>
			</div>

			<div class="border border-solid border-blue-200">
				<datatable :data="companies" :columns="columns" :query="searchQuery" :loading="loading" ref="table" />
			</div>

		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Welcome to your dashboard.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Take a look around and familiarize yourself with the system.</p>
					<p class="mb-2">When you're ready to begin you can start by <b class="text-black">creating a new company</b>.</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				actions: [
					{
						text: 'Delete',
						color: 'red-500'
					}
				],
				columns: [
					{
						name: 'name',
						th: 'Company Name',
					},
					{
						name: 'tier',
						th: 'Company Tier',
						render: (company, tier) => `Tier ${tier}`
					},
					{
						name: 'employees',
						th: 'No. Of Staff',
						render: company => company.users?.length || 0
						// render: company => company.profile?.no_of_staff ? company.profile?.no_of_staff : 0
					},
					{
						name: 'profile',
						th: 'Status',
						render: (company, profile) => {
							if (profile) {
								return `<div class="badge badge-green-soft-outline badge-sm">
									Confirmed
								</div>`;
							}
							return `<div class="badge badge-orange-soft-outline badge-sm">
								Pending
							</div>`;
						}
					},
					{
						name: 'created_at',
						th: 'Onboarding Date',
						render: (row, created_at) => this.$options.filters.dateFormat(created_at, 'D dd M, Y h:ia')
					},
					{
						name: '',
						th: 'Action',
						render: (company) => `
							<div class="flex flex-row items-center justify-between">
								<button
									class="btn btn-blue"
									data-click="toUsers(${company.id}, ${company.name})"
								>
									View All Users
								</button>
							</div>
						`
					},
				],
				company: null,
				companies: [],
				loans: [],
				loading: false,
				modalKey: 'dashboard-super-help',
				pending_loans: [],
				searchQuery: '',

			}
		},
		computed: {
			position() {
				return this.user?.profile ? this.user.profile.position : 'Admin';
			},
			totalStaff() {
				// let total = 0;
				// this.companies.forEach(el => {
				// 	el.users.forEach(el => {
				// 		total = (el.profile) ? total + 1 : total + 0;
				// 	});
				// });
				// return total;
				return this.companies.reduce((last, company) => last + (company.users?.length || 0), 0)
			},
			totalLoanAmount() {
				return this.loans.reduce((last, loan) => last + Number(loan.loan_amount), 0);
			}
		},
		beforeMount() {
			this.getCompany();
			this.getCompanies();
			this.getLoans();
			this.getPendingLoans();
		},
		mounted() {
			this.$options.listenForClick.apply(this);
			this.openModal(this.modalKey, this.$refs.helpModal);
		},
		methods: {

			async getCompany() {
				this.$get({
					url: `${this.$baseurl}/companies/${this.user.company_id}`,
					headers: this.headers,
					success: (response) => {
						this.company = response.data.company;
					},
					error: (error) => {
						console.log(error);
					}
				});
			},
			async getCompanies() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies`,
					headers: this.headers,
					success: response => {
						this.companies = response.data.data;
					}
				});
				this.loading = false;
			},
			async getLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/loans`,
					headers: this.headers,
					success: response => {
						this.loans = response.data.data;
					}
				});
				this.loading = false;
			},
			async getPendingLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/loans/pending`,
					headers: this.headers,
					success: response => {
						this.pending_loans = response.data.data;
					}
				});
				this.loading = false;
			},
			toUsers(company_id, company_name) {
				this.$router.push({ name: 'companies-users', params: { company_id }, query: { company_name } });
			}
		}
	}
</script>
